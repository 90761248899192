<template>
  <div class="copyright">
    Copyright © 2022 云雾草庄
    <br>
    <a href="https://beian.miit.gov.cn">浙ICP备11004749号-1</a>
  </div>
</template>

<script>
export default {
  setup () {

  }
}
</script>

<style lang="scss" scoped>
.copyright {
  background-color: #475050;
  text-align: center;
  color: #fff;
  font-size: .14rem;
  line-height: 1.7;
  padding: .4rem 0;
  a {
    text-decoration: none;
    color: #fff;
    font-weight: 600;
  }
}
</style>
