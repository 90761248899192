<template>
  <header class="navbar">
    <a
      href="/"
      aria-current="page"
      class="home-link router-link-exact-active router-link-active"
    >
      <img src="../assets/logo.png" alt="Vue.js" class="logo" />
      <span class="site-name can-hide">云雾草庄</span>
    </a>
    <nav class="nav right">
      <ul>
        <li :class="{current: itemClass[0]}" @click="handleItem(0, $event)"><router-link to="/home">首页</router-link></li>
        <li :class="{current: itemClass[1]}" @click="handleItem(1, $event)"><a href="http://bbs.xuande.xyz">交流区</a></li>
        <!--<li :class="{current: itemClass[2]}" @click="handleItem(2, $event)"><router-link to="/wqjd">往期经典</router-link></li> -->
        <li :class="{current: itemClass[3]}" @click="handleItem(3, $event)"><router-link to="/download">录音下载</router-link></li>
        <li :class="{current: itemClass[4]}" @click="handleItem(4, $event)"><router-link to="/about">关于</router-link></li>
      </ul>
    </nav>
  </header>
  <div class="main">
    <main class="content">
      <router-view />
    </main>
    <footer class="footer">
      <Footer/>
    </footer>
  </div>
</template>

<script>
import { reactive } from 'vue'
import Footer from '@/components/Footer.vue'

export default {
  setup () {
    const itemClass = reactive([true, false, false, false, false])
    const handleItem = (num, event) => {
      for (let i = 0; i < itemClass.length; i++) {
        if (i !== num) {
          itemClass[i] = false
        } else {
          itemClass[i] = true
        }
      }
    }

    return { itemClass, handleItem }
  },
  components: {
    Footer
  }
}
</script>
<style lang="scss">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  // text-align: center;
  color: #2c3e50;
  font-size: 0.18rem;
  height: 100%;
  // position: relative;
}
.main {
  display: flex;
  flex-direction: column;
  // height: 100%;
  min-height: 100%;
  .content {
    flex-grow: 1;
    // height: 100%;
    min-height: 100%;
    padding-top: .6rem;
    // position: relative;
  }
  .footer {
    flex-shrink: 0;
    // position: relative;
}
}

.navbar {
  display: block;
  position: fixed;
  z-index: 20;
  top: 0;
  left: 0;
  right: 0;
  height: 0.6rem;
  line-height: 0.4rem;
  padding: 0.11rem 0.24rem;
  background-color: #fff;
  box-sizing: border-box;
  border-bottom: 1px solid #eaecef;
  a {
    display: inline-block;
    text-decoration: none;
  }
}
.navbar .site-name {
  font-size: 0.2rem;
  font-weight: 500;
  color: #2c3e50;
  position: relative;
}
.navbar .logo {
  height: 0.28rem;
  min-width: 0.36rem;
  margin-right: 0.05rem;
  vertical-align: middle;
}

/* nav styles */
/* nav */
.nav {
  display: flex;
  // position: relative;
  position: absolute;
  right: 0.5rem;
  top: 0.1rem;
  box-sizing: border-box;
  // margin: 20px 0;
}
.nav ul {
  margin: 0;
  padding: 0;
}
.nav li {
  margin: 0 8px 10px 0;
  padding: 0;
  list-style: none;
  display: inline-block;
}
.nav a {
  padding: 3px 12px;
  text-decoration: none;
  // color: #999;
  color: #2c3e50;
  line-height: 100%;
}
.nav a:hover {
  color: #000;
}
.nav .current a {
  background: #999;
  color: #fff;
  border-radius: 5px;
}
/* right nav */
.nav.right ul {
  text-align: right;
}

@media screen and (max-width: 600px) {
  .nav {
    // position: relative;
    position: absolute;
    min-height: 40px;
    right: 0.1rem;
  }
  .nav ul {
    width: 120px;
    padding: 5px 0;
    position: absolute;
    top: 0;
    left: 0;
    border: solid 1px #aaa;
    background: #fff url(../assets/icon-menu.png) no-repeat 10px 11px;
    border-radius: 5px;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.3);
  }
  .nav li {
    display: none; /* hide all <li> items */
    margin: 0;
  }
  .nav .current {
    display: block; /* show only current <li> item */
  }
  .nav a {
    display: block;
    padding: 5px 5px 5px 32px;
    text-align: left;
  }
  .nav .current a {
    background: none;
    color: #666;
  }

  /* on nav hover */
  .nav ul:hover {
    background-image: none;
  }
  .nav ul:hover li {
    display: block;
    margin: 0 0 5px;
  }
  .nav ul:hover .current {
    background: url(../assets/icon-check.png) no-repeat 10px 7px;
  }

  /* right nav */
  .nav.right ul {
    left: auto;
    right: 0;
  }

  /* center nav */
  .nav.center ul {
    left: 50%;
    margin-left: -90px;
  }
}
/* nav styles end. */
</style>
